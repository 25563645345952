<template>
  <a-row>
    <a-col :span="16" style="padding-right:8px">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formState"
        :rules="rules"
      >
        <a-form-item label="姓名" name="name">
          <a-input v-model:value="formState.name" block/>
        </a-form-item>
        <a-form-item label="公司名称" name="corp_name">
          <a-input v-model:value="formState.corp_name" />
        </a-form-item>
        <a-form-item label="邮箱" name="email">
          <a-input v-model:value="formState.email" />
        </a-form-item>
        <a-form-item label="公司地址" name="address">
          <a-textarea v-model:value="formState.address" :rows="4" />
        </a-form-item>
        <a-form-item >
          <a-button type="primary" @click="onSubmit">更新基本信息</a-button>
        </a-form-item>
      </a-form>
    </a-col>
    <a-col :span="8" style="padding-left:8px;padding-right:8px">
      <div class="">
        <div class="upload-preview" @click="warning">
          <div class="mask">
          </div>
          <img src="../../assets/123.jpg" alt="">
        </div>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRaw } from 'vue';
import { message } from 'ant-design-vue';
import { getUserInfo, register } from '../../api';
export default defineComponent({
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: 1,
      corp_name:'',
      email:'',
      address:''
    });
    const rules = {
      name: {
        required: true,
        message: '请输入姓名',
      },
      corp_name: {
        required: true,
        message: '请输入公司昵称',
      },
      email: {
        required: true,
        message: '请输入邮箱',
      },
      address: {
        required: true,
        message: '请输入公司地址',
      },
    };

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(async() => {
          let id = window.sessionStorage.getItem('user-id')
          const res = await register({...toRaw(formState),id})
          message.success(res.msg)
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };
    const warning = () => {
      message.warning('别点啦，这块还在开发呢');
    };
    const init = async ()=>{
      let id = window.sessionStorage.getItem('user-id')
      const res = await getUserInfo(id)
      let { name, corp_name, email, address }=res.userInfo
      formState.name = name
      formState.corp_name = corp_name
      formState.email = email
      formState.address = address
    }
    onMounted(init)
    return {
      formRef,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      formState,
      rules,
      onSubmit,
      resetForm,
      warning
    };
  },
});
</script>

<style scoped lang='less'>
  
  .upload-preview{
    position: relative;
    width: 180px;
    height: 180px;
    margin: auto;
    border-radius: 50%;
    box-shadow: 0 0 4px #ccc;
  }
  .upload-preview .mask,
  .upload-preview img{
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  .mask{
    opacity: 0;
    position: absolute;
    background-color: rgba(0,0,0,.4);
    cursor: pointer;
    transition: opacity .4s;
  }
  .mask:hover{
    opacity: 1;
  }
</style>
